import React from 'react';
import { Helmet } from 'react-helmet';

import config, { AppMode } from '@config';
import metadata from '@values/metadata';

interface PageMetadataProps {
	language?: 'uk' | 'en';
}

function usePageMetadataProps({ language = 'uk' }: PageMetadataProps) {
	const title = metadata.title[language] || metadata.title.en;
	const description = metadata.description[language] || metadata.description.en;

	return {
		title,
		description,
	};
}

export const PageMetadata: React.FC<PageMetadataProps> = (props) => {
	const { title, description } = usePageMetadataProps(props);

	return (
		<Helmet title={title}>
			<meta property='og:title' content={title} />
			<meta property='og:type' content='website' />

			<meta name='description' content={description} />
			<meta property='og:description' content={description} />
			{config.mode === AppMode.Production && <meta name='facebook-domain-verification' content='nepjc6wimuion4tis2oceilkuyftre' />}
		</Helmet>
	);
};
