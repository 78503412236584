import * as React from 'react';

import { LogoIcon } from '@shared/icons/logo';
import { TryzubIcon } from '@shared/icons/tryzub';
import { Text } from '@shared/ui/text';
import { GenericLink } from '@shared/ui/GenericLink';
import { useLanguage } from '@hooks/useLanguage';
import { useWindowScroll } from 'react-use';
import clsx from 'clsx';

interface HeaderBaseProps {
	headerRef?: React.RefObject<HTMLDivElement> | null;
	children?: React.ReactNode;
	containerClasses?: string;
	logoClasses?: string;
}

function useComponentState(props: HeaderBaseProps) {
	const language = useLanguage();
	const { y: windowScrollY } = useWindowScroll();
	const hasWindowScroll = windowScrollY > 0;
	const headerStyles = clsx('sticky left-0 top-0 right-0 py-2 z-30 lg:py-4 bg-white transition-shadow', {
		'shadow-header': hasWindowScroll,
	});

	const containerClasses = props.containerClasses ?? 'container_2xl flex items-center';
	const logoClasses = props.logoClasses ?? 'flex items-center mr-auto xl:mr-4.875';

	return {
		...props,
		language,
		headerStyles,
		containerClasses,
		logoClasses,
	};
}

export const HeaderBase: React.FC<HeaderBaseProps> = (props) => {
	const { containerClasses, logoClasses, headerRef = null, children, language, headerStyles } = useComponentState(props);

	return (
		<header ref={headerRef} className={headerStyles}>
			<div className={containerClasses}>
				<div className='flex items-center mr-1 xs:mr-4'>
					<GenericLink className={logoClasses} type='page' to='/'>
						<TryzubIcon className='shrink-0 w-[2rem] h-[2rem] mr-2 md:w-[3rem] md:h-[3rem]' />
						<LogoIcon className='shrink-0 w-[2rem] h-[2rem] mr-2 md:w-[3rem] md:h-[3rem] md:mr-4' />
						<Text weight='bold' size='sm' className='md:text-base'>
							{language === 'uk' ? 'Допомога' : 'Dopomoga'}
						</Text>
					</GenericLink>
				</div>
				{children}
			</div>
		</header>
	);
};
