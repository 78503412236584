import * as React from 'react';

import { HeaderBase } from './HeaderBase';

import { LanguageSelect } from '@shared/ui/selects/LanguageSelect';

import mastercardFullLogo from '@assets/images/events/mastercard-full.svg';
import { MspLogoIcon } from '@shared/icons/msp';

export const HeaderMastercardEvent = () => {
  return (
    <HeaderBase
      containerClasses="container mx-auto flex items-center justify-between md:justify-start lg:max-w-[1280px]"
      logoClasses="flex items-center md:mr-3.125"
    >
      <MspLogoIcon className="hidden lg:block lg:mr-4.875" />

      <LanguageSelect className="ml-auto lg:ml-0 lg:mr-4.875" />

      <img src={mastercardFullLogo} width={268} height={47} className="hidden lg:block" />
    </HeaderBase>
  );
};
