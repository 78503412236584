import React from 'react';
import { Provider } from 'react-redux';

import { store } from '../store';
import { LanguageProvider } from './providers/LanguageProvider';

import 'react-toastify/dist/ReactToastify.css';

export const WrapRootElement = ({ element }: any) => {
  return (
    <Provider store={store}>
      <LanguageProvider>{element}</LanguageProvider>
    </Provider>
  );
};
