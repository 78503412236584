import { LogoIcon } from '@shared/icons/logo';
import { TryzubIcon } from '@shared/icons/tryzub';
import { GenericLink, OuterLink } from '@shared/ui/GenericLink';
import { LanguageSelect } from '@shared/ui/selects/LanguageSelect';
import { Text } from '@shared/ui/text';
import { useRules } from '@widgets/events/mastercard/useRules';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const FooterMastercardEvent = () => {
  const { t } = useTranslation();
  const { rulesDocLink } = useRules();

  return (
    <footer className="py-3.125 text-white text-base bg-black">
      <div className="container_2xl flex flex-col lg:flex-row justify-between">
        <div className="flex items-center mb-5 lg:mb-0">
          <GenericLink className="flex mr-3" type="page" to="/">
            <TryzubIcon className="shrink-0 mr-2 w-[3rem] h-[3rem] text-white" />
            <LogoIcon className="shrink-0 w-[3rem] h-[3rem]" />
          </GenericLink>
          <Text size="xxs" className="opacity-70">
            edopomoga.gov.ua <br /> 2022. {t('rights-protected')}
          </Text>
        </div>

        <div className="flex flex-col md:flex-row items-start md:items-center space-y-5 md:space-y-0 md:space-x-8">
          <GenericLink
            type="inner"
            to={rulesDocLink}
            className="hover:underline"
          >
            {t('official-rules-short')}
          </GenericLink>
          <LanguageSelect mode="dark" menuPlacement="top" />
          <div className="flex flex-col xs:flex-row whitespace-nowrap">
            <div className="mr-2"> {t('our-contacts')}:</div>

            <OuterLink to="mailto:help@edopomoga.gov.ua" className="underline">
              help@edopomoga.gov.ua
            </OuterLink>
          </div>
        </div>
      </div>
    </footer>
  );
};
